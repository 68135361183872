<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-08-07 13:59:05.196 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <div
        :class="{'open': open}"
        class="yqg-icon-nav"
        v-on="$listeners"
    >
        <span />
        <span />
        <span />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgIconNav',

        props: {
            open: {
                type: Boolean,
                default: false
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-icon-nav {
        position: relative;
        cursor: pointer;

        span {
            position: absolute;
            display: block;
            height: 2px;
            width: 100%;
            background-color: #666;
            transition: 0.2s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                left: 0;
                margin-top: -1px;
            }

            &:nth-child(3) {
                bottom: 0;
            }
        }

        &.open {
            span {
                position: absolute;

                &:nth-child(1) {
                    top: 50%;
                    margin-top: -1px;
                    transform: rotate(135deg);
                }

                &:nth-child(2) {
                    opacity: 0%;
                    left: -40px;
                }

                &:nth-child(3) {
                    top: 50%;
                    margin-top: -1px;
                    transform: rotate(-135deg);
                }
            }
        }
    }

</style>
