var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      { staticClass: "yqg-icon-nav", class: { open: _vm.open } },
      _vm.$listeners
    ),
    [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }